<template>
  <div class="chatting-wrap vjs-fullscreen">
    <div class="top-area">
      <div class="chatting-container"  :class="!isToggleChatBox ? 'off' : ''" v-show="!status.banned" >
        <!-- @click.prevent="chatClick" -->
        <!-- <scroll-down :classe="'market'" v-show="noScroll" @scrollBtn="scrollBtn" /> -->

        <ul v-show="isToggleChatBox"  class="chatting-box scroll-no" ref="chatbox"
        >
        <!-- @scroll="handleScroll" -->
          <li v-for="(list, idx) in message" :key="idx + 'list'" :class="list.userType" >
            <div class="color-box" v-if="list.type === 'message'">
              <span class="user-name">
                {{
                  typeID(
                    list.userType,
                    list.nickName,
                  )
                }}
              </span>
              <span>{{ list.comment }}</span>
            </div>
            <div class="color-box" v-else>
              <span>{{ list.comment }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="popup-wrap"  v-show="!status.banned">
        <a :href="false" @click.prevent="showDetail"  >
          <div class="item-img">
            <img
              :src="shop.item.url.image"
              :alt="influencerShop.title + '상품 이미지'"
            />
          </div>
          <ul class="item-info">
            <li class="item-name overtext">
              {{ influencerShop.title }}
            </li>
            <li class="item-account">
              <strong
                >{{pricePercent}}</strong
              >
              <span
                >{{
                  numberConvertComma(shop.item.price.group)
                }}원</span
              >
            </li>
          </ul>
          <div class="more-btn">
              <strong class="arrow"></strong>
              <span>더보기</span>
          </div>
        </a>
        <!-- <button  @click="toggleChatBox"></button> -->
      </div>
      <div class="function-box" v-if="noLogin">
          <button class="go-chat-btn overtext" @click.prevent="goLogin">
            대화를 나눠보세요.
          </button>
          <button v-show="!status.banned" class="chat-toggle-button" :disabled="status.muted"  @click="toggleChatBox">
            <img
              src="../../assets/img/chat.svg"
              alt="chat img"
            />
          </button>
          <!-- <button         
            class="full-screen-btn"
            @click.prevent="fullScreen('toggle')"
          >
            <img
              v-if="isFullScreen"
              src="../../assets/img/resize.svg"
              alt="fullView"
            />
            <img
              v-else
              src="../../assets/img/full.svg"
              alt="fullView"
            />
          </button> -->
      </div>
      <div class="function-box" v-else :class="status.banned ? 'ban' : ''">
        <template v-if="isFullScreen === true">
          <input v-show="!status.banned"
            id="in_chat"
            ref="keyboard"
            type="text"
            autocomplete="off"
            placeholder="대화를 나눠보세요."
            :value="comment"
            :disabled="status.muted"
            @input="setIsComment($event)"
            @keyup.enter="submit"
            @focus="inputFocus(true)"            
            @blur="inputFocus(false)"            
          />
          <div v-show="!status.banned" class="send-button">
            <button @click.prevent="submit" :disabled="status.muted">
              <img srcset="../../assets/img/ma_se@3x.png 3x, ../../assets/img/ma_se@3x.png 3x, ../../assets/img/ma_se@4x.png 4x" src="../../assets/img/ma_se.png" alt="chat toggle button">
            </button>
          </div>
        </template>
        <template v-else>
          <button  v-show="!status.banned" class="go-chat-btn overtext" @click.prevent="fullScreen('on')">
            대화를 나눠보세요.
          </button>
        </template>
        <a class="popup" :href="false" @click.prevent="showDetail"  v-if="status.banned" >
          <div class="item-img" :style="{ backgroundImage: 'url(' + shop.item.url.image + ')' }"></div>
          <ul class="item-info">
            <li class="item-name overtext">
              {{ influencerShop.title }}
            </li>
            <li class="item-account">
              <strong
                >{{pricePercent}}</strong
              >
              <span
                >{{
                  numberConvertComma(shop.item.price.group)
                }}원</span
              >
            </li>
          </ul>
          <div class="more-btn">
              <strong class="arrow"></strong>
              <span>더보기</span>
          </div>
        </a>
        <!-- <button class="mute-btn" @click.prevent="fullScreen">mute</button> -->
        <button v-show="!status.banned" class="chat-toggle-button" @click="toggleChatBox">
          <img
              src="../../assets/img/chat.svg"
              alt="chat img"
            />
        </button>
        <!-- <button         
          class="full-screen-btn"
          @click.prevent="fullScreen('toggle')"
        >
          <img
            v-if="isFullScreen"
            src="../../assets/img/resize.svg"
            alt="fullView"
          />
          <img
            v-else
            src="../../assets/img/full.svg"
            alt="fullView"
          />
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
const { VUE_APP_URL } = process.env;
import ScrollDown from '../modules/ScrollDown.vue'
export default {
    name: "market-mobile-realchat",
    data() {
        return {
            comment: "", // chat v-model data
            myComment: {}, // chat data
            isFullScreen: false, // fullScreen status
            isToggleChatBox: true, // chat box hide 
            scrollTop: 0,
            status: {
              banned: false,
              muted: false
            },
            timer: null,
            isOnline: null,
        };
    },
    components: {
      ScrollDown
    },

    async created() {
        // 유저 정보 받아오기
        await this.$store.dispatch('setUserInfo', this.$route.query.token);

        // 웹소켓 생성 함수 사용하기
        await this.$store.dispatch('wsInit', 'real');
        
        // 웹워커 생성 함수 사용하기
        await this.$store.dispatch('initChatworker');
    },

    async mounted() {

      if (navigator.onLine) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
      // 앱 전체화면 활용
      if(this.$route.query.full){
        this.isFullScreen = this.$route.query.full === '1' ? true : false;
      }
      await window.addEventListener('message', this.getMessage);

      if(window.android){
        window['getMessage'] = this.getMessage;
        // window['setUserData'] = this.setUserData;
      }

      window.addEventListener('offline', (e) => { console.log('offline');
        this.isOnline = false;
        this.timer = setInterval(() => {
          this.$store.dispatch('wsInit', 'real');
        }, 2000);
      });
      window.addEventListener('online', (e) => { console.log('online');
        this.isOnline = true;
        if(this.ws){
          clearInterval(this.timer);
        }
      });
    },

    async beforeUnmount() {
        await this.$store.commit('stopInter');
        await this.$store.commit('chatClose');
        clearInterval(this.timer);
    },

    async beforeDestroy() {
        await window.removeEventListener('message', this.getMessage);
        window.addEventListener('offline', (e) => { console.log('offline');
          this.isOnline = false;
          this.timer = setInterval(() => {
            this.$store.dispatch('wsInit', 'real');
          }, 2000);
        });
        window.addEventListener('online', (e) => { console.log('online');
          this.isOnline = true;
          if(this.ws && this.ws.readyState === 1){
            clearInterval(this.timer);
          }
        });
        clearInterval(this.timer);
    },

    computed: {
        userInfo(){
            return this.$store.state.chatStore.userInfo;
        },
        user(){
            return this.$store.state.chatStore.userInfo.user;
        },
        influencerShop(){
            return this.$store.state.chatStore.userInfo.influencerShop;
        },
        shop(){
            return this.$store.state.chatStore.userInfo.shop;
        },
        regularWorker(){
            return this.$store.state.chatStore.chatWorker;
        },
        ws(){
            return this.$store.state.chatStore.ws;
        },
        message(){
            return this.$store.state.chatStore.messages;
        },
        // timer(){
        //     return this.$store.state.chatStore.inter_timer;
        // },
        noScroll(){
            return this.$store.state.chatStore.noScroll;
        },
        noLogin(){
          return this.user.nickname.indexOf('_guest_') != -1 || this.user.nickname.indexOf('_noMember_') != -1 ? true : false;
        },
        pricePercent() {
            return this.shop &&
                this.shop.item.price.group &&
                this.shop.item.price.retail 
                ? 100 -
                      Math.floor(
                          (Number(this.shop.item.price.group) * 100) /
                              Number(this.shop.item.price.retail )
                      ) +
                      '%'
                : null;
        },
    },
    updated(){
        if(this.noScroll){
            return; 
        } else {
            this.scrollAuto();
        }
    },
    methods: {
      setIsComment(e){
        setTimeout(()=> {
          this.comment = e.target.value
          if(this.comment.length > 40){
            this.comment = this.comment.slice(0, 40);
            if(window.android){
              return window.android.postMessage("limitText","최대 40자까지 입력 가능합니다");
            }
            return window.parent.postMessage({ status: "limitText"}, "*");
          }
        },10)
      },
      typeID(type, nickName) {
        if (type === "supervisor") {
          return "라마";
        } else {
          return nickName;
        }
      },
      toggleChatBox(type){
        this.isToggleChatBox = !this.isToggleChatBox;
        console.log('this.isToggleChatBox', this.isToggleChatBox);
        if(this.isToggleChatBox === false){
          window.parent.postMessage({ status: "chatBox", value: false }, "*");
        } else {
          window.parent.postMessage({ status: "chatBox", value: true }, "*");
        }
      },
      async submit() {
          if(this.comment.trim() == ""){
            this.comment = '';
            if(window.android){
              return window.android.postMessage("text","텍스트를 입력해주세요");
            }
            return window.parent.postMessage({ status: "text"}, "*");
          }
          if(this.id === ''){
              return alert('아이디를 입력해주세요');
          }
          this.$store.commit('sendWs', this.comment + '|' + this.user.id + '|' + this.typeID(this.user.userType, this.user.nickname) + '|' + this.user.type + '|' + 'message' + '\n')
          this.comment = '';
      },
      fullScreen(type) {    
        console.log(type, 'full');
        // app 브릿지
        if(window.android){
          if (window.android.action(type)){
            return false;
          } 
        }
        
        if (type === "toggle") {
          window.parent.postMessage(
            { status: "fullpage", value: this.isFullScreen },
            "*"
          );
          this.isFullScreen = !this.isFullScreen;
        } else if (type === "on") {
          window.parent.postMessage({ status: "fullpage", value: true }, "*");
          this.isFullScreen = true;
        } else if (type === "off") {
          
          if (this.isFullScreen === true) {
            window.parent.postMessage({ status: "fullpage", value: false }, "*");
            this.isFullScreen = false;
          }
         
        }
        setTimeout( () => {
          this.scrollBtn(false);
        }, 500)
      },
      showDetail (){
        window.parent.postMessage({ status: "detail", value: true }, "*");
      },
      goLogin(){
        if(window.android){
            if (window.android.action('on')){
              return false;
          }
        }
        window.parent.postMessage(
          { status: "goLogin", value: true },
          "*"
        );
        
      },
      handleScroll(e){
          var top = e.target.scrollTop;
          if(this.scrollTop  > top){
              console.log('스크롤 올림');
              if(!this.noScroll){
                this.scrollBtn(true);
                console.log('스크롤 고정 해제');
              }
          } else {
              console.log('스크롤 내림');
          }
          this.scrollTop = top;
      },
      scrollBtn(boolean){
          this.$store.commit('noScroll', boolean);
          if(boolean === false){
              console.log('스크롤 고정 시작');
              this.scrollAuto();
          }
      },
      scrollAuto(){
            setTimeout(() => {
                this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
            }, 0)
        },
      getMessage(e){
        if(window.android){
          if (e === 'bannedTrue') {
            this.status.banned = true;
          } else if (e === 'bannedFalse') {
            this.status.banned = false;
            this.status.muted = false;
          } else if (e === 'mutedTrue') {
            this.status.muted = true;
          } else if (e === 'mutedFalse') {
            this.status.muted = false;
          } 
          return e;
        }
        
        if(e.origin === VUE_APP_URL){
          if (e.data === 'bannedTrue') {
            this.status.banned = true;
          } else if (e.data === 'bannedFalse') {
            this.status.banned = false;
            this.status.muted = false;
          } else if (e.data === 'mutedTrue') {
            this.status.muted = true;
          } else if (e.data === 'mutedFalse') {
            this.status.muted = false;
          } 
        }
      },
      chatClick(){
        if(window.android){
          if (window.android.action('chatClick')){
            return false;
          }
        } else {
          window.parent.postMessage({ status: "chat-click", value: true }, "*");
        }
      },
      inputFocus(type){
        window.parent.postMessage({ status: "input", value: type }, "*");
      }
  },
};
</script>

<style lang="scss" scoped>

input:focus {
  outline: none;
}

.chatting-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  // height: 50%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  // background: url(https://images.unsplash.com/photo-1610981263015-ef95481e9ffb?ixid=MXwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw4fHx8ZW58MHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60) center no-repeat;
  > .top-area {
    // background: rgba(0, 0, 0, 0.2);
    height: 100vh;
    // padding-bottom: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: column;
    
    > .chatting-container {
      position: relative;
      display: inline-block;
      bottom: 0;
      border-radius: 0 0 29px 29px;
      width: 100%;
      max-height: calc(100% - 130px);
      // height: calc(100% - 210px);
      // height: calc(100% - 170px);
      z-index: 60;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-flow: column;
      > .chatting-box {
        width: calc(100% - 60px);
        padding: 0 0 10px 20px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        > li {
          margin-bottom: 5px;
          > .color-box {
            display: inline-block;  
            padding: 0 6px;   
            line-height: 20px;       
            > span {
              // display: inline-block;
              color: white;
              text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
              font-size: 14px;
              word-break: break-all;
            }
            > .user-name {
              // color: #e2e2e2;
              color: #eee;
              margin-right: 10px;
              font-family: "Noto Sans CJK KR Medium";
            }
          }
        }
        > li:last-child {
          margin-bottom: 0;
        }
        > li.supervisor {
          > .color-box {
            background: rgba(38, 153, 251, 0.8);
            border-radius: 10px;
            padding: 2px 6px;
            > .user-name {
              color: #fff;
            }
          }
        }
        > li.advertiser {
          > .color-box {
            background: rgba(84, 212, 175, 0.8);
            border-radius: 10px;
            padding: 2px 6px;
            > .user-name {
              color: #fff;
            }
          }
        }
        > li.influencer {
          > .color-box {
            background: rgba(114, 133, 255, 0.8);
            border-radius: 10px;
            padding: 2px 6px;
            > .user-name {
              color: #fff;
            }
          }
        }
      }
    }
    > .chatting-container.off {
      height: 0;
    }
    > .popup-wrap {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 70px);
      // max-width: 280px;
      box-sizing: border-box;
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        > .item-img {
            min-width: 42px;
            max-width: 42px;
            height: 50px;
            margin-right: 10px;
            > img {
                width: 100%;
            }
        }
        > .item-info {
            width: calc(100% - 104px);
            box-sizing: border-box;
            > .item-name {
                font-size: 12px;
                color: #000000;
                font-family: 'NotoSansKR-Medium';
            }
            > .item-account {
                display: flex;
                justify-content: left;
                align-items: center;
                > strong {
                    display: inline-block;
                    font-size: 14px;
                    color: #ff0000;
                    margin-right: 5px;
                    font-family: 'NotoSansKR-Bold';
                }
                > span {
                    display: inline-block;
                    font-size: 14px;
                    color: #000000;
                    font-family: 'NotoSansKR-Bold';
                }
            }
        }
        > .more-btn {
            min-width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            border-left: 1px solid #d9d9d9;
            > .arrow {
                position: relative;
                width: 20px;
                height: 20px;
                background: url(../../assets/img/enter.svg)
                    no-repeat center / contain;
            }
            > span {
                margin-top: -6px;
                display: inline-block;
                font-size: 12px;
                color: #000;
                font-family: 'NotoSansKR-Medium';
            }
        }
      }
      > button {
        width: 20px;
        height: 20px;
      }
    }    
    > .function-box.ban {
      justify-content: space-between;
    }
    > .function-box {
      position: relative;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: olivedrab;
      border-radius: 0 0 29px 29px;
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      .overtext {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
      }
      // bottom: 0;
      > input {
        // left: 35%;
        margin-right: 16px;
        width: calc(100% - 40px);
        height: 40px;
        // transform: translateX(-50%);
        // bottom: 5%;
        border: 2px solid #fff;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.2);
        font-size: 14px;
        color: #fff;
        padding: 0 45px 0 20px;
        box-sizing: border-box;
      }
      > .length-count {
        position: absolute;
        left: calc(100% - 145px);
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
        width: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > span {
          color: #E2E1E1;
          font-size: 10px;
          text-shadow: 1px 1px 0 rgba(0,0,0,.16);
          font-family: "Noto Sans CJK KR Light";

        }
      }
      > .length-count.no {
        > span {
          color: #f00;
        }
      }
      > .send-button {
        position: absolute;
        left: calc(100% - 125px);
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
        width: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > button {
          background:transparent;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
      > .go-chat-btn {
        text-align: left;
        margin-right: 16px;
        width: calc(100% - 40px);
        height: 40px;
        // transform: translateX(-50%);
        // bottom: 5%;
        border: 2px solid #fff;
        border-radius: 30px;
        background: rgba(0, 0, 0, 0.2);
        font-size: 14px;
        color:#fff;
        padding: 0 20px;
        box-sizing: border-box;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.16);
      }
      > input::placeholder {
        color: #fff;
      }
      > .mute-btn {
        margin-right: 5px;
        width: 40px;
        height: 40px;
      }
      > .full-screen-btn {
          border: 0;
          background: transparent;
          padding: 0;
          box-sizing: border-box;
          display: flex;
       
      }
      > .chat-toggle-button {
          margin-right: 15px;
          border: 0;
          background: transparent;
          padding: 0;
          box-sizing: border-box;
          display: flex;
        
      }
      > .popup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(100% - 70px);
        background: #fff;
        border-radius: 5px;
        // max-width: 280px;
        box-sizing: border-box;
        > .item-img {
            min-width: 42px;
            max-width: 42px;
            height: 50px;
            margin-right: 10px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        > .item-info {
            width: calc(100% - 104px);
            box-sizing: border-box;
            > .item-name {
                font-size: 12px;
                color: #000000;
                font-family: 'NotoSansKR-Medium';
            }
            > .item-account {
                display: flex;
                justify-content: left;
                align-items: center;
                > strong {
                    display: inline-block;
                    font-size: 14px;
                    color: #ff0000;
                    margin-right: 5px;
                    font-family: 'NotoSansKR-Bold';
                }
                > span {
                    display: inline-block;
                    font-size: 14px;
                    color: #000000;
                    font-family: 'NotoSansKR-Bold';
                }
            }
        }
        > .more-btn {
            min-width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            border-left: 1px solid #d9d9d9;
            > .arrow {
                position: relative;
                width: 20px;
                height: 20px;
                 background: url(../../assets/img/enter.svg)
                    no-repeat center / contain;
            }
            > span {
                margin-top: -6px;
                display: inline-block;
                font-size: 12px;
                color: #000;
                font-family: 'NotoSansKR-Medium';
            }
        }
      }
    }
  }
}

@keyframes noticeMove {
  0% {left: 100%;}
  100% {left: -100%;}
}

// .list-enter-active, .list-leave-active {
//   transition: all 0.1s ease-out;
// }
// .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
//   opacity: 0;
//   // transform: translateY(30px);
// }

.notice-enter-active, .notice-leave-active {
    transition: all 15s;

}
.notice-enter, .notice-leave-to {
    left: 100%;
}

</style>