<template>
    <li class="scroll-down" :class="classe ? classe : ''">
        <button class="button auto-scroll" @click.prevent="scrollBtn(false)">
            <span class="arrow-img"></span>
            최신 채팅으로 가기
        </button>
    </li>
</template>

<script>
export default {
    props: ['classe'],
    methods:{
        scrollBtn(Boolean){
            this.$emit('scrollBtn', Boolean);
        }
    }
}
</script>

<style lang="scss">
.scroll-down.market {
    bottom: 10px;
}
.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 55px;
  transform: translateX(-50%);
  max-width: 165px !important;
  > button {
    width: 100%;
    height: 26px;
    border-radius: 42px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.5);
    background: #292929;
    border: none;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    .arrow-img {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 50px;
        margin-right: 5px;
    }
    .arrow-img::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url('../../assets/img/down.svg') no-repeat center / cover;
        width: 10px;
        height: 5px;
    }
  }
}

</style>