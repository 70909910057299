import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

Vue.prototype.numberConvertComma = num => {
  return num
      ? num.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : 0;
};


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
