<template>
  <div class="side-content" id="oper-admin-chat">
    <h4 class="side-content-title">관리자 채팅</h4>
    <div class="side-content-box">
      <div class="admin-chat-box">
        <ul name="list" class="chat-list" ref="chatbox" @scroll="handleScroll">
          <!-- 채팅 로그 -->
          <scroll-down  v-show="noScroll" @scrollBtn="scrollBtn" />
          <li
            v-for="(list, idx) in message"
            :key="idx + 'list'"
            :class="list.id === user.id ? 'my-chat' : ''"
          >
            <ul :class="list.userType" v-if="list.type === 'message'">
              <li class="user-name">
                <span>{{
                  typeID(list.userType, list.nickName) +
                  "(" +
                  list.id +
                  ")"
                }}</span>
                <!-- list.type -->
              </li>
              <!-- 내가 보냈을 때 -->
              <li class="user-chat" v-if="list.id === user.id">
                <span class="chat-time">{{
                  $moment(list.time, 'YYYY/MM/DD-HH:mm:ss').format("HH:mm")
                }}</span>
                <span class="chat-comment">{{ list.comment }}</span>
              </li>
              <!-- 남이 보냈을 때 -->
              <li class="user-chat" v-else>
                <span class="chat-comment">{{ list.comment }}</span>
                <span class="chat-time">{{
                  $moment(list.time, 'YYYY/MM/DD-HH:mm:ss').format("HH:mm")
                }}</span>
              </li>
            </ul>
            <!-- 입장 -->
            <ul v-else-if="list.type === 'enter'">
              <li>{{list.comment}}</li>
            </ul>
          </li>
        </ul>
        <div class="chat-board">
          <input
            type="text"
            placeholder="대화를 나눠보세요"
            :value="comment"
            @input="setIsComment($event)"
            @keyup.enter="submit"
          />
          <button
            @click.prevent="submit"
            :disabled="comment == '' ? true : false"
            :class="comment == '' ? 'disabled' : ''"
          >
            전송
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ScrollDown from '../modules/ScrollDown.vue'

export default {
    name: "op-cl-adminChat",
    data() {
        return {
            comment: "",
            chatList: [],
            isComment: "",
            myComment: {},
            isNoticeModal: false,
            noticeText: null,
        };
    },
    components: {
        ScrollDown
    },
    computed: {
        userInfo(){
            return this.$store.state.chatStore.userInfo;
        },
        user(){
            return this.$store.state.chatStore.userInfo.user;
        },
        influencerShop(){
            return this.$store.state.chatStore.userInfo.influencerShop;
        },
        regularWorker(){
            return this.$store.state.chatStore.chatWorker;
        },
        ws(){
            return this.$store.state.chatStore.ws;
        },
        message(){
            return this.$store.state.chatStore.messages;
        },
        timer(){
            return this.$store.state.chatStore.inter_timer;
        },
        noScroll(){
            return this.$store.state.chatStore.noScroll;
        }
    },

    updated(){
        if(this.noScroll){
            return; 
        } else {
            this.scrollAuto();
        }
    },

    async created() {
        // 유저 정보 받아오기
        await this.$store.dispatch('setUserInfo', this.$route.query.token);

        // 웹소켓 생성 함수 사용하기
        await this.$store.dispatch('wsInit', 'admin');
        
        // 웹워커 생성 함수 사용하기
        await this.$store.dispatch('initChatworker');
    
    },
  

    async mounted() {
        // window.addEventListener(
        // "message",
        // function (e) {
        //     if (
        //     e.origin === "http://192.168.0.57:8080" ||
        //     "http://192.168.0.57:8080" ||
        //     "https://stage.client.bigbird.co.kr" ||
        //     "https://client.bigbird.co.kr" ||
        //     "https://operator.bigbird.co.kr" ||
        //     "https://stage.operator.bigbird.co.kr"
        //     ) {
        //     // e.data를 사용한 동작 수행
        //     // this.message = e.data;
        //     // console.log(e.data, "로 로그아웃 하셨습니다.");
        //     if (e.data) {
        //         this.fb_chat_logout();
        //         window.parent.postMessage(
        //         { status: "logout", value: true },
        //         "http://192.168.0.57:8080" ||
        //             "https://stage.client.bigbird.co.kr" ||
        //             "https://client.bigbird.co.kr" ||
        //             "https://operator.bigbird.co.kr" ||
        //             "https://stage.operator.bigbird.co.kr"
        //         );
        //     }
        //     }
        //     // this.message = document
        //     //   .getElementById("admin-chat-frame")
        //     //   .contentWindow.postMessage(e.data, "*");
        // }.bind(this)
        // );
    },

    beforeDestroy() {
        // window.removeEventListener("message", function (e) {
        // if (
        //     e.origin === "http://192.168.0.57:8080" ||
        //     "https://stage.client.bigbird.co.kr" ||
        //     "http://client.bigbird.co.kr" ||
        //     "https://operator.bigbird.co.kr" ||
        //     "https://stage.operator.bigbird.co.kr"
        // ) {
        //     // e.data를 사용한 동작 수행
        //     // this.message = e.data;
        //     // console.log(e.data, "로 로그아웃 하셨습니다.", this.message);
        // }
        // });
    },

    methods: {
        setIsComment(e){
            this.comment = e.target.value;
        },
        typeID(type, nickName) {
            if (type === "supervisor") {
                return "라마";
            } else {
                return nickName;
            }
               
        },
        submit() {
            if(this.comment.trim() == ""){
                return alert('텍스트를 입력해주세요');
            }

            if(this.id === ''){
                return alert('아이디를 입력해주세요');
            }
            this.$store.commit('sendWs', this.comment + '|' + this.user.id + '|' + this.typeID(this.user.userType, this.user.brandName) + '|' + this.user.type + '|' + 'message' + '\n')
            this.comment = '';
        },    
        handleScroll(e){
            var top = e.target.scrollTop;
            if(this.scrollTop  > top){
                console.log('스크롤 올림');
                if(!this.noScroll){
                this.scrollBtn(true);
                console.log('스크롤 고정 해제');
                }
            } else {
                console.log('스크롤 내림');
            }
            this.scrollTop = top;
        },
        scrollBtn(boolean){
            this.$store.commit('noScroll', boolean);
            if(boolean === false){
                console.log('스크롤 고정 시작');
                this.scrollAuto();
            }
        },
        scrollAuto(){
            setTimeout(() => {
                    this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
            }, 0)
        },
    },
};
</script>
<style lang="scss" scoped>

</style>