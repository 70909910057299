<template>
    <div id="influencer-admin">
        <influencer-admin-chat-component />
    </div>
</template>

<script>
import InfluencerAdminChatComponent from "@/components/influencer/InfluencerAdminChatComponent.vue";

export default {
    components: {
        InfluencerAdminChatComponent
    }
}
</script>

<style>

</style>