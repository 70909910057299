<template>
  <div class="dh-chat-wrap">
        <div v-if="message" class="chat-box image-bg" ref="chatbox" @scroll="handleScroll" >
            <button class="button auto-scroll" @click.prevent="scrollBtn(false)">↓</button>
            
            <ul class="chat-lists" v-for="(list, idx) in message" :key="idx+ 'chat'">
                <!-- 메세지 -->
                <li v-if="list.type === 'message'" class="chat-message">
                    <span>{{list.id ? list.id + ':' : null}}</span>
                    
                    <span>{{list.comment ? list.comment : null}}</span>
                </li>
                <!-- 이미지 -->
                <li v-else-if="list.type === 'image'" class="chat-image">
                    <span>{{list.id ? list.id + ':' : null}}</span>
                    <div class="chat-image-box" :style="list.comment ? { backgroundImage: 'url(' + list.comment + ')' } : null"></div>
                </li>
                <!-- 입장, 퇴장 -->
                <li v-else class="chat-notice">
                    <span>{{list ? list : null}}</span>
                </li>
            </ul>
            
        </div>
        <div class="chat-nav">
            <input type="text"  :value="comment"  @input="setComment($event)" @keyup.enter="submit">
            <button @click="submit">전송</button>
        </div>
        <div class="chat-option">
            <input type="text" v-model="id" placeholder="아이디 입력해주세요"> <button @click="inter">부하버튼</button><button @click="stop">중지버튼</button>
        </div>


        <!-- 잠시 대기 -->
        <!-- <button class="button upload" @click.prevent="uploadImage">★</button>
            <input ref="file" type="file" @input="showFile($event)" style="width: 0; height:0;display: none;" accept="image/gif, image/jpeg, image/png"> -->
  </div>
</template>

<script>
export default {
    name: 'chat-component',
    props: ['data'],
    data(){
        return {
            // 유저 아이디
            id: '',
            // 유저 코멘트
            comment: '',

            // inter() 데이터
            count: 0,
            scrollTop: 0,

            file: null
        }
    },
    computed:{
        // store에 있는 데이터 사용
        regularWorker(){
            return this.$store.state.chatStore.chatWorker;
        },
        ws(){
            return this.$store.state.chatStore.ws;
        },
        message(){
            return this.$store.state.chatStore.messages;
        },
        timer(){
            return this.$store.state.chatStore.inter_timer;
        },
        noScroll(){
            return this.$store.state.chatStore.noScroll;
        }
    },
    updated(){
        if(this.noScroll){
           return; 
        } else {
            this.scrollAuto();
        }
    },
    async created() { 

        // 웹소켓 생성 함수 사용하기
        await this.$store.dispatch('wsInit', 'real');
        
        // 웹워커 생성 함수 사용하기
        await this.$store.dispatch('initChatworker');
    },
    beforeDestroy(){
        this.ws.close();
        // inter 종료
        this.$store.commit('stopInter');
    },
    methods: {
        // 입력데이터 데이터 처리 이벤트
        setComment(e){
            this.comment = e.target.value
        },
        // 메세지 입력 이벤트
        submit(){
            if(this.comment.trim() == ""){
                return alert('텍스트를 입력해주세요');
            }

            if(this.id === ''){
                return alert('아이디를 입력해주세요');
            }
            this.$store.commit('sendWs', this.comment + '|' + this.id + '|' + 'message')
            this.comment = '';
        },
        inter(){
            if(this.timer !== null){
                return;
            }

            if(this.id === ''){
                return alert('아이디를 입력해주세요');
            }

            this.$store.commit('timerInter', this.id);
        },

        stop(){
            this.$store.commit('stopInter');
        },

        scrollAuto(){
            setTimeout(() => {
                // if(!this.noScroll){
                    this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
                // } else {
                //     return;
                // }
            }, 0)
        },
        scrollBtn(boolean){
            this.$store.commit('noScroll', boolean);
            if(boolean === false){
                // console.log('스크롤 고정 시작');
                this.scrollAuto();
            }
        },
        handleScroll(e){
            var top = e.target.scrollTop;
            if(this.scrollTop  > top){
                // console.log('스크롤 올림');
                if(!this.noScroll){
                this.scrollBtn(true);
                // console.log('스크롤 고정 해제');
                }
            } else {
                // console.log('스크롤 내림');
            }
            this.scrollTop = top;
        },

        // 파일업로드 관련 함수
        showFile(e){
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);

            this.file = url;
            this.ws.send(url + '|' + this.id + '|' + 'image');
            e.target.value = '';
        },
        uploadImage(){
            this.$refs.file.click();
        }
    }
}
</script>

<style>

</style>