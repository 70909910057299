<template>
    <div id="operator-admin">
        <admin-chat-component />
    </div>
</template>

<script>
import AdminChatComponent from "@/components/oper/AdminChatComponent.vue";

export default {
    components: {
        AdminChatComponent
    }
}
</script>

<style>

</style>