<template>
  <div class="home">
    <header id="header"><h3>채팅</h3></header>
    <article id="content">
      <chat-component />
    </article>
  </div>
</template>
<script>
import ChatComponent from '../components/ChatComponent.vue';
export default {
  name: 'Home',
  data(){
    return {
    }
  },
  components: {
    ChatComponent
  }
}
</script>
<style lang="scss">
html, body, h1, h2, h3, h4, h5, h6, p, ul, li, ol, span {
  margin: 0;
  padding: 0;
}



#app {
  width: 100%;
  height: 100%;
  .home {
    width: 100%;
    height: 100%;
    background: #fff;
    #header {
      position: fixed;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      z-index: 9;
    }
    #header:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #000;
      
    }
    #content {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: calc(100% - 50px);
      .chat-box {
        position: absolute;
        bottom: 60px;
        width: 100%;
        height: calc(100% - 60px);
        padding: 10px;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        > p {
          padding: 5px 0;
          text-align: left;
        }
        > .auto-scroll {
          position: fixed;
          width: 24px;
          height: 24px;
          bottom: 70px;
          right: 10px;
          background: #fff;
          border: none;
          border-radius: 50px;
          box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
          z-index: 999;
        }
      }
      .chat-nav {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: transparent;
        z-index: 9;
        > input {
          width: calc(100% - 70px);
          height: 50%;
          box-sizing: border-box;
        }
        > button {
          min-width: 50px;
          height: 50%;
          box-sizing: border-box;
        }
      }
      // .chat-nav:after {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 1px;
      //   background: #000;
      // }

      .chat-option {
        position: absolute;
        right: 5px;
        top: 5px;
        display: flex;
        justify-content: center;
        flex-flow: column;
        gap: 5px;

        > input {
          width: 120px;
          height: 30px;
          box-sizing: border-box;
        }
        > button {
          width: 120px;
          height: 25px;
        }
      }

      .image-bg {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
