<template>
    <div id="market-real-chat">
        <market-real-chat-component />
    </div>
</template>

<script>
import MarketRealChatComponent from "@/components/market/MarketRealChatComponent.vue";

export default {
    components: {
        MarketRealChatComponent
    }
}
</script>

<style>

</style>