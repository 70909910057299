<template>
    <div id="influencer">
        <influencer-real-chat-component />
    </div>
</template>

<script>
import InfluencerRealChatComponent from "@/components/influencer/InfluencerRealChatComponent.vue";

export default {
    components: {
        InfluencerRealChatComponent
    }
}
</script>

<style>

</style>