import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import Operator from '../views/operator/index.vue'
import OperatorAdmin from '../views/operator/admin.vue'
import Client from '../views/client/index.vue'
import ClientAdmin from '../views/client/admin.vue'
import Market from '../views/market/index.vue'
import Influencer from '../views/influencer/index.vue'
import InfluencerAdmin from '../views/influencer/admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name : 'Test',
    component: Test
  },
  {
    path: '/operator',
    name : 'Operator',
    component: Operator
  },
  {
    path: '/operator_admin',
    name : 'OperatorAdmin',
    component: OperatorAdmin
  },
  {
    path: '/client',
    name : 'Client',
    component: Client
  },
  {
    path: '/client_admin',
    name : 'ClientAdmin',
    component: ClientAdmin
  },
  {
    path: '/market',
    name : 'Market',
    component: Market
  },
  {
    path: '/influencer',
    name : 'Influencer',
    component: Influencer
  },
  {
    path: '/influencer_admin',
    name : 'InfluencerAdmin',
    component: InfluencerAdmin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
