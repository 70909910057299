import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);


import chatStore from '@/store/chat.js';

const store = new Vuex.Store({
  modules: {
    chatStore : chatStore,
  }
});


export default store

  