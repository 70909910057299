<template>
    <div id="client">
        <ci-real-chat-component />
    </div>
</template>

<script>
import CiRealChatComponent from "@/components/client/CiRealChatComponent.vue";

export default {
    components: {
        CiRealChatComponent
    }
}
</script>

<style>

</style>