<template>
  <section class="modal-content scroll" id="content" ref="chatbox">
    <ul v-for="(list, idx) in message" :key="idx" :class="list.userType">
      <template v-if="list.type === 'message'">
        <li class="user-name" >
          <div class="user-name">
            <span>{{
              typeID(list.userType, list.nickName) +
              "(" +
              list.id +
              ")"
            }}</span>
          </div>
        </li>
        <li>
          <div class="chat-box">
            <span class="comment"> {{ list.comment }}</span>
          </div>
          <div class="chat-date">
            <span>{{ $moment(list.time).format("HH:mm") }}</span>
          </div>
        </li>
      </template>
      <li v-else>
        {{ list.comment }}
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "influencer-mobile-admin-chat",
  data() {
    return {
      scrollTop: 0,   
    };
  },
  async created() {
      // 유저 정보 받아오기
      await this.$store.dispatch('setUserInfo', this.$route.query.token);

      // 웹소켓 생성 함수 사용하기
      await this.$store.dispatch('wsInit', 'admin');
      
      // 웹워커 생성 함수 사용하기
      await this.$store.dispatch('initChatworker');
      
  },
  async mounted() {
      window.addEventListener('beforeunload', this.unLoadEvent);
  },

  async beforeUnmount() {
      await this.$store.commit('stopInter');
      await this.$store.commit('chatClose');
      window.removeEventListener('beforeunload', this.unLoadEvent);
  },

  computed: {
      userInfo(){
          return this.$store.state.chatStore.userInfo;
      },
      campaign(){
          return this.$store.state.chatStore.userInfo.campaign;
      },
      user(){
          return this.$store.state.chatStore.userInfo.user;
      },
      influencerShop(){
          return this.$store.state.chatStore.userInfo.influencerShop;
      },
      regularWorker(){
          return this.$store.state.chatStore.chatWorker;
      },
      ws(){
          return this.$store.state.chatStore.ws;
      },
      message(){
          return this.$store.state.chatStore.messages;
      },
      timer(){
          return this.$store.state.chatStore.inter_timer;
      },
      noScroll(){
          return this.$store.state.chatStore.noScroll;
      }
  },
  updated(){
      if(this.noScroll){
          return; 
      } else {
          this.scrollAuto();
      }
  },
  
  methods: {
    typeID(type, nickName) {
      if (type === "supervisor") {
        return "라마";
      } else {
        return nickName;
      }
    },
    handleScroll(e){
        var top = e.target.scrollTop;
        if(this.scrollTop  > top){
            console.log('스크롤 올림');
            if(!this.noScroll){
            this.scrollBtn(true);
            console.log('스크롤 고정 해제');
            }
        } else {
            console.log('스크롤 내림');
        }
        this.scrollTop = top;
    },
    scrollBtn(boolean){
        this.$store.commit('noScroll', boolean);
        if(boolean === false){
            console.log('스크롤 고정 시작');
            this.scrollAuto();
        }
    },
    scrollAuto(){
        setTimeout(() => {
            this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
        }, 0)
    },   
   
    unLoadEvent(){
      if(this.isLeaveSite) return;
      if(this.user){
        // if(this.user.nickname !== '_guest_'){
        //   this.$store.commit('sendWs', `${this.user.nickname}님이 퇴장하셨습니다.||||enter\n`);
        // }
      } 
    }
    // getMarketChat(message) {
    //   this.chatList.push(message.data);
    //   console.debug("Market message :", message);
    // },
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
}
.modal-content {
  width: 100%;
  height: 100%;
  > ul {
    width: 100%;
    > li.user-name {
      margin-top: 6px;
      height: auto;
    }
    > li {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: flex-end;
      margin-bottom: 10px;

      > .chat-box {
        padding: 8px 10px;
        border-radius: 10px;
        background: #2699fb;
        max-width: calc(100% - 58px);
        > .comment {
          color: #fff;
          font-size: 14px;
          font-family: "Noto Sans CJK KR Medium";
        }
      }
      > .user-name {
        display: block;
        font-family: "Noto Sans CJK KR Medium";
        font-size: 14px;
        color: #000;
      }
      > .chat-date {
        margin-left: 10px;
        margin-bottom: 5px;
        > span {
          display: inline-block;
          font-size: 12px;
          color: #bbbbbb;
        }
      }
    }
  }
  > ul.supervisor {
    > li {
      > .user-name {
        > span {
          color: #2699fb;
        }
      }
      > .chat-box {
        background: #2699fb;
        > .comment {
          color: #fff;
        }
      }
    }
  }
  > ul.advertiser {
    > li {
      > .user-name {
        > span {
          color: #54d4af;
        }
      }
      > .chat-box {
        background: #54d4af;
        > .comment {
          color: #fff;
        }
      }
    }
  }
  > ul.influencer {
    > li {
      > .user-name {
        > span {
          color: #7285ff;
        }
      }
      > .chat-box {
        background: #7285ff;
        > .comment {
          color: #fff;
        }
      }
    }
  }
}
</style>