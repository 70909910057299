<template>
  <div class="live-user-chat-box ">
      <div class="chat-container scroll-no" ref="chatbox">
        <div
            class="chat-list"
            v-for="(list, idx) in message"
            :key="idx + 'chatlist'"
            :class="list.userType"
        >
            <div class="color-box" v-if="list.type === 'message'">
              <span class="user-id">
                  {{
                  typeID(
                      list.userType,
                      list.nickName
                  ) 
                  }}
              </span>
              <span class="user-comment">{{ list.comment }}</span>
            </div>
            <div class="color-box none-color" v-else>
              <span class="user-comment">{{ list.comment }}</span>
            </div>   
        </div>   
      </div>
  </div>
</template>

<script>
export default {
  name: "influencer-mobile-real-chat",
  data() {
    return {
      scrollTop: 0,
    };
  },
  async created() {
      // 유저 정보 받아오기
      await this.$store.dispatch('setUserInfo', this.$route.query.token);

      // 웹소켓 생성 함수 사용하기
      await this.$store.dispatch('wsInit', 'real');
      
      // 웹워커 생성 함수 사용하기
      await this.$store.dispatch('initChatworker');
      
  },
  async mounted() {
      window.addEventListener('beforeunload', this.unLoadEvent);
  },

  async beforeUnmount() {
      await this.$store.commit('stopInter');
      await this.$store.commit('chatClose');
      window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  computed: {
      userInfo(){
          return this.$store.state.chatStore.userInfo;
      },
      campaign(){
          return this.$store.state.chatStore.userInfo.campaign;
      },
      user(){
          return this.$store.state.chatStore.userInfo.user;
      },
      influencerShop(){
          return this.$store.state.chatStore.userInfo.influencerShop;
      },
      regularWorker(){
          return this.$store.state.chatStore.chatWorker;
      },
      ws(){
          return this.$store.state.chatStore.ws;
      },
      message(){
          return this.$store.state.chatStore.messages;
      },
      timer(){
          return this.$store.state.chatStore.inter_timer;
      },
      noScroll(){
          return this.$store.state.chatStore.noScroll;
      }
  },
  updated(){
      if(this.noScroll){
          return; 
      } else {
          this.scrollAuto();
      }
  },
  methods: {
    typeID(type, nickName) {
      if (type === "supervisor") {
        return "라마";
      } else {
        return nickName;
      }
    },
    handleScroll(e){
        var top = e.target.scrollTop;
        if(this.scrollTop  > top){
            console.log('스크롤 올림');
            if(!this.noScroll){
            this.scrollBtn(true);
            console.log('스크롤 고정 해제');
            }
        } else {
            console.log('스크롤 내림');
        }
        this.scrollTop = top;
    },
    scrollBtn(boolean){
        this.$store.commit('noScroll', boolean);
        if(boolean === false){
            console.log('스크롤 고정 시작');
            this.scrollAuto();
        }
    },
    scrollAuto(){
        setTimeout(() => {
            this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
        }, 0)
    },

    unLoadEvent(){
      if(this.isLeaveSite) return;
      if(this.user){
        // if(this.user.type !== 'guest'){
        //   this.$store.commit('sendWs', `${this.user.nickname}님이 퇴장하셨습니다.||||enter\n`);
        // }
      } 
    }
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
}
.live-user-chat-box {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
  
  // height: 100%;
  > .chat-container {
    overflow: auto;
    padding: 0 0 10px 10px;
    box-sizing: border-box;
    > .chat-list {
      text-align: left;
      margin-bottom: 5px;
    > .color-box {
      display: inline-block;  
      padding: 0 6px;   
      line-height: 20px;   
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      > .user-id {
        color: #eee;
        margin-right: 10px;
        font-family: "Noto Sans CJK KR Medium";
        font-size: 14px;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      }
      > .user-comment {
        color: #fff;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        font-size: 14px;
        word-break: break-all;
      } 
    }
  }
  > .chat-list:last-child {
      margin-bottom: 0;
    }
  > .chat-list.supervisor {
    > .color-box {
        background: rgba(38, 153, 251, 0.8);
        border-radius: 10px;
        padding: 2px 6px;
        > .user-comment {
          color: #fff;
        }
        > .user-id {
          color: #fff;
        }
      }
  }
  > .chat-list.advertiser {
   > .color-box {
      background: rgba(84, 212, 175, 0.8);
      border-radius: 10px;
      padding: 2px 6px;
      > .user-comment {
        color: #fff;
      }
      > .user-id {
          color: #fff;
        }
    }
  }
  > .chat-list.influencer {
    > .color-box {
      background: rgba(114, 133, 255, 0.8);
      border-radius: 10px;
      padding: 2px 6px;
      > .user-comment {
        color: #fff;
      }
      > .user-id {
          color: #fff;
        }
    }
  }
  }
  
}
</style>