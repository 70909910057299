<template>
    <div id="operator">
        <real-chat-component />
    </div>
</template>

<script>
import RealChatComponent from "@/components/oper/RealChatComponent.vue";

export default {
    components: {
        RealChatComponent
    }
}
</script>

<style>

</style>