<template>
  <div class="home">
    <article id="content">
      <h1>V2 채팅 프로젝트</h1>
    </article>
  </div>
</template>
<script>

export default {
  name: 'Home',
  data(){
    return {
    }
  },
  components: {
  },
  created(){
    console.log(process.env);
    
  },
  computed:{
  },
  methods:{
  }
}
</script>
<style lang="scss">

</style>
