<template>
      <div class="real-chat-box" id="oper-real-chat">
        <!-- 채팅 로그 -->
        <ul class="chat-list"  ref="chatbox" @scroll="handleScroll">
          <scroll-down  v-show="noScroll" @scrollBtn="scrollBtn" />
          <li
            v-for="(list, idx) in message"
            :key="idx + 'list'"
            :class="list.id === user.id ? 'my-chat' : ''"
          >
          <!-- 메세지 -->
            <ul :class="list.userType" v-if="list.type === 'message'">
              <li class="user-name">
                <a href="#" @click.prevent="showUserInfo($event, list)" v-if="list.userType === 'purchaser'" :disabled="false">
                  {{ typeID(
                    list.userType,
                    list.nickName
                  ) +
                  "(" +
                  list.id +
                  ")"}}
                </a>     
                <a :href="false" :disabled="true" v-else>
                  {{ typeID(
                    list.userType,
                    list.nickName
                  ) +
                  "(" +
                  list.id +
                  ")"}}
                </a>                
              </li>
              <!-- 내가 보냈을 때 -->
              <li class="user-chat" v-if="list.id === user.id">
                <span class="chat-time">{{
                    $moment(list.time, 'YYYY/MM/DD-HH:mm:ss').format("HH:mm")
                }}</span>
                <span class="chat-comment">{{ list.comment }}</span>
              </li>
              <!-- 남이 보냈을 때 -->
              <li class="user-chat" v-else>
                <span class="chat-comment">{{ list.comment }}</span>
                <span class="chat-time">{{
                    $moment(list.time, 'YYYY/MM/DD-HH:mm:ss').format("HH:mm")
                }}</span>
              </li>
            </ul>
            <!-- 입장 -->
            <ul v-else-if="list.type === 'enter'">
              <li>{{list.comment}}</li>
            </ul>
          </li>
          
        </ul>
        <div class="chat-board">
          <input
            type="text"
            placeholder="대화를 나눠보세요"
            :value="comment"
            @input="setIsComment($event)"
            @keyup.enter="submit"
            :disabled="user.nickname === '_guest_' ? true : false"
          />
          <button @click.prevent="submit" :disabled=" user.nickname === '_guest_' ? true : false">전송</button>
        </div>
      </div>
</template>

<script>
import '../../assets/scss/operator.scss'; 
import ScrollDown from '../modules/ScrollDown.vue'
export default {
  name: "op-cl-realchat",
  data() {
    return {
      comment: "",

      chatList: [],
      isComment: "",
      myComment: {},
      noticeText: null,
      isNoticeModal: false,
      isUserInfoModal: false,
      infoLeft: null,
      infoTop: null,
      useInfoData: null,
      chatty: null,
      all: null,
      loggedIn: null,
      noMember: null,
      guest: null,
      isSupervisor: false,
      loadTimer: 0,
      scrollPosition: 0,
      scrollMode: false,
      scrollTop: 0,
    };
  },
  components: {
    ScrollDown
  },

  async created() {
    // 유저 정보 받아오기
    await this.$store.dispatch('setUserInfo', this.$route.query.token);

    // 웹소켓 생성 함수 사용하기
    await this.$store.dispatch('wsInit', 'real');
    
    // 웹워커 생성 함수 사용하기
    await this.$store.dispatch('initChatworker');
    
  },

  async mounted() {
    // if(this.userInfo.user.type === "supervisor"){
    //       this.isSupervisor = true;
    // }
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  async beforeUnmount() {
    console.log('종료');
    await this.$store.commit('stopInter');
    await this.$store.commit('chatClose');
    window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  computed: {
    userInfo(){
      return this.$store.state.chatStore.userInfo;
    },
    user(){
      return this.$store.state.chatStore.userInfo.user;
    },
    influencerShop(){
      return this.$store.state.chatStore.userInfo.influencerShop;
    },
    regularWorker(){
      return this.$store.state.chatStore.chatWorker;
    },
    ws(){
      return this.$store.state.chatStore.ws;
    },
    message(){
      return this.$store.state.chatStore.messages;
    },
    timer(){
      return this.$store.state.chatStore.inter_timer;
    },
    noScroll(){
      return this.$store.state.chatStore.noScroll;
    },
    liveTime(){
      
      return this.$moment().format("YYYY.MM.DD HH:mm") >= this.$moment(this.influencerShop.date.start, 'YYYY.MM.DD HH:mm').format("YYYY.MM.DD HH:mm");
    }
  },
  updated(){
      if(this.noScroll){
          return; 
      } else {
          this.scrollAuto();
      }
  },
  methods: {
    setIsComment(e){
        this.comment = e.target.value;
    },
    typeID(type, nickName) {
      if (type === "supervisor") {
        return "라마";
      } else {
        return nickName;
      }
    },
    
    async submit() {
      if(!this.liveTime){
         return alert('라이브 시간에 채팅가능합니다.');
      }

      if(this.comment.trim() == ""){
            return alert('텍스트를 입력해주세요');
        }

        if(this.id === ''){
            return alert('아이디를 입력해주세요');
        }
        this.$store.commit('sendWs', this.comment + '|' + this.user.id + '|' + this.typeID(this.user.userType, this.user.brandName) + '|' + this.user.type + '|' + 'message' + '\n')
        this.comment = '';
    },
    handleScroll(e){
        var top = e.target.scrollTop;
        if(this.scrollTop  > top){
            console.log('스크롤 올림');
            if(!this.noScroll){
              this.scrollBtn(true);
              console.log('스크롤 고정 해제');
            }
        } else {
            console.log('스크롤 내림');
        }
        this.scrollTop = top;
    },
    scrollBtn(boolean){
        this.$store.commit('noScroll', boolean);
        if(boolean === false){
            console.log('스크롤 고정 시작');
            this.scrollAuto();
        }
    },
    scrollAuto(){
          setTimeout(() => {
              this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
          }, 0)
      },
    unLoadEvent(){
      if(this.isLeaveSite) return;
      if(this.user){
        // if(this.user.nickname !== '_guest_'){
        //   this.$store.commit('sendWs', `${this.user.nickname}님이 퇴장하셨습니다.||||enter\n`);
        // }
      } 
    }
  },
};
</script>

<style lang="scss" scoped>


</style>