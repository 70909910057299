<template>
    <div id="client-admin">
        <ci-admin-chat-component />
    </div>
</template>

<script>
import CiAdminChatComponent from "@/components/client/CiAdminChatComponent.vue";

export default {
    components: {
        CiAdminChatComponent
    }
}
</script>

<style>

</style>