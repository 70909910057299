import JWT from 'jsonwebtoken';
const { VUE_APP_SECRET, VUE_APP_sessionKey } = process.env;
const chatStore = {
    state: { 
        // 웹소켓
        ws: null,
        // 채팅 웹워커
        chatWorker: null,
        // 채팅 입력 시간 차이
        chatWorkerOldTime: new Date().getTime(),

        // 보여질 채팅 히스토리
        messages: [],

        // 채팅 한번에 담는 함수용 변수
        chat_basket: [],
        chat_timer: null,

        // 부하테스트 용 변수
        inter_timer: null,
        inter_count: 0,

        // 채팅 스크롤 상태
        noScroll: false,

        // JWT 토큰 시크릿키
        secret: 'BigBird',

        // 로그인 유저 데이터
        userInfo: null,

        re_connect_timer: null
    },
    mutations: {
        // 웹소켓 설정
        setWs(state, payload){
            state.ws = null;
            // console.log('setWs', state.userInfo.user.chatUrl, state.userInfo.user.chatUrlForAdmin, payload);
            if(payload == 'real'){
                state.ws = new WebSocket('wss://' + state.userInfo.user.chatUrl);
            } else if (payload == 'admin') {
                state.ws = new WebSocket('wss://' + state.userInfo.user.chatUrlForAdmin);
            }
        },

        // 웹소켓 메세지 보내기
        sendWs(state, payload){
            state.ws.send(payload);
            // console.log(state.ws);
            // console.log('보내는 :', payload);
        },

        // 채팅 웹 워커 설정
        setChatWorker(state){
            state.chatWorker = new Worker('/js/worker.js');
            // console.log('setChatWorker =>', state.chatWorker);
        },

        // 채팅 웹 워커 일시키기
        workChatWorker(state, paylood){
            state.chatWorker.postMessage(paylood);
        },

        // 메세지 보내기
        showMessage(state, payload){
            state.messages.push(payload);
        },
        

        // 채팅 시간 차이 업데이트 함수
        oldTimer(state, payload){
            state.chatWorkerOldTime = payload;
        },

        // 바스켓에 담아서 메세지 한번에 보여주는 함수
        inter(state, payload){
            let one_string = payload.data.result.split('\n');
            let string = one_string[0].split('|');
            let box = {
                idx: string[0],
                time:string[1],
                comment: string[2],
                id: string[3],
                nickName: string[4],
                userType: string[5],
                type: string[6],
            }
            
            state.chat_basket.push(box);

            if(state.chat_timer !== null){
                return;
            }
            // console.log('인터벌 일한다!');
            state.chat_timer = setInterval((e) => {
                let box = [...state.messages, ...state.chat_basket];
                state.messages = box;
                state.chat_basket = [];

                clearInterval(state.chat_timer);
                state.chat_timer = null;

                // console.log('인터벌 쉴게!');
            }, 100);
        },

        // 담지않고 바로 메세지 보여주는 함수
        direct(state, payload){
            let data = [...state.messages];
            let one_string = payload.data.result.split('\n');
            let string = one_string[0].split('|');
            let box = {
                idx: string[0],
                time:string[1],
                comment: string[2],
                id: string[3],
                nickName: string[4],
                userType: string[5],
                type: string[6],
            }
            data.push(box);

            state.messages = data;
        },

        // 부하테스트 시작 함수
        timerInter(state, payload){
            state.inter_timer = setInterval(() => {
                state.inter_count+=1;
                state.ws.send(state.inter_count + '|' + payload + '|' + 'message')
            }, 100);
        },

        // 부하테스트 종료 함수
        stopInter(state){
            clearInterval(state.inter_timer);
            state.inter_timer = null;
            clearInterval(state.chat_timer);
            state.chat_timer = null;
        },

        // 채팅 스크롤 상태값 변경 함수
        noScroll(state, payload){
            state.noScroll = payload;
        },

        // JWT-token decode
        jwtDecode(state, payload){
            if (!payload) {
                console.error('Invalid token');
                return;
            }
            state.userInfo = JWT.verify(payload, VUE_APP_SECRET);
            // console.log(state.userInfo);
        },


        chatClose(state, payload){
            state.ws.close();
            state.ws = null;
            // console.log('종료');
        },
        guest(state, payload){
            function getUuId() {
                return (
                    '_guest_' +
                    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                        /[xy]/g,
                        function(c) {
                            var r = (Math.random() * 16) | 0,
                                v = c == 'x' ? r : (r & 0x3) | 0x8;
                            return v.toString(16);
                        }
                    )
                );
            }
            let user = {
                id: getUuId(),
                nickname: `ehdgus441`,
                type: "purchaser",
                chatUrl: "stage.chat.bigbirdmarket.com:12333",
                chatUrlForAdmin: "15.165.217.68:12501"
            }
            state.userInfo = {
                shop: {
                    item: {
                        url: {
                            image:
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png"
                            },
                        price: {
                            retail: "2000",
                            group: "1000"
                            }
                        }
                    },
                influencerShop: {
                    title: "갤럭시 워치 X 컨셉터스",
                    date: {
                        start: "2020.11.09 12:27",
                        end: "2021.03.04 11:55"
                    }
                },
                user: user,
            } 
        },
    },
    actions: {

        wsInit(context, payload){
            
            if(context.state.ws && context.state.ws.readyState === 3){
                context.commit('setWs', payload);
            } 

            if(!context.state.ws){
                context.commit('setWs', payload);
            }

            // 시작
            context.state.ws.onopen = (event) => {
                console.log('연결', event);
                
                if(window.android){
                    window.android.action('onopen');
                }

                window.parent.postMessage({ status: "ready"}, '*');
                if(context.state.userInfo){
                    if(context.state.userInfo.user.nickname !== '_guest_'){

                        if(context.state.userInfo.user.type === 'advertiser' || context.state.userInfo.user.type === 'supervisor'){
                            // context.commit('sendWs', `CHATLINES=-1`);
                            context.commit('sendWs', `SESSIONKEY=${VUE_APP_sessionKey}CHATLINES=-1`);
                        } else {
                            // context.commit('sendWs', `CHATLINES=15`);
                            context.commit('sendWs', `SESSIONKEY=${VUE_APP_sessionKey}CHATLINES=15`);
                        }
                        // context.commit('sendWs', `${context.state.userInfo.user.nickname ? context.state.userInfo.user.nickname : context.state.userInfo.user.id}님이 입장하셨습니다.||||enter\n`);
                    } else {
                        // context.commit('sendWs', `CHATLINES=15`);
                        context.commit('sendWs', `SESSIONKEY=${VUE_APP_sessionKey}CHATLINES=15`);
                    }
                }  else {
                    // context.commit('sendWs', `CHATLINES=15`);
                    context.commit('sendWs', `SESSIONKEY=${VUE_APP_sessionKey}CHATLINES=15`);
                }
            }
            // 종료
            context.state.ws.onclose = async (event) => {
                console.log('onclose', event);

                if(window.android){
                   return window.android.action('onclose')
                } 
                
            };
            // 데이터 보내기
            context.state.ws.onmessage = (event) => {
                context.commit('workChatWorker', event.data);
            }
            // 에러
            context.state.ws.onerror = function (event) {
                console.log('onerror',event);

                if(window.android){
                    window.android.action('onerror');
                } else {
                    context.commit('chatClose');
                }
            };

            
        },

        initChatworker(context, payload){
            if(!context.state.chatWorker){
               context.commit('setChatWorker');
            }
            
            // 웹워커가 일 다하고 여기로 데이터 전달해줌
            context.state.chatWorker.onmessage = (event) => {
                var time = new Date().getTime();

                // console.log('메세지 받는 :' , event);
                    
                if(time - context.state.chatWorkerOldTime > 1000){
                    context.commit('direct', event);
                } else {
                    context.commit('inter', event);
                }
                context.commit('oldTimer', time);
            }
            
        },

        setUserInfo(context, payload){
            if(payload){
                context.commit('jwtDecode', payload);
            } else {
                // console.log('게스트');
                context.commit('guest');
            }
        }
    }
}


export default chatStore;